
import { Component, Vue, Prop } from "vue-property-decorator";
import gHeader from "@/components/g-header.vue";
import { addCart, isInCart } from "@/assets/js/Utils";
import { Getter, Mutation, State } from "vuex-class";

@Component({
  components: {
    gHeader,
  },
})
export default class CVRender3D extends Vue {
  private modelId = "";
  private title = "产品详情";
  private cHeight = document.documentElement.clientHeight - 48;
  private cWidth = document.documentElement.clientWidth;
  private domain = "https://s.eggi.cn/3d/index.html";
  private isInCart = false;
  private loaded = false;
  private renderUrl = "about:blank";
  @State goodsCount: any;
  @State("computeValueNavList") navList: any;
  @State("computeValueIndex") navIndex: any;
  @Getter("curComputeValuePage") curPage: any;
  @Mutation updateComputeValueIndex: any;

  goBackTop(): void {
    this.$router.back();
  }

  jumpToCart(): void {
    if (this.curPage == "cart") {
      this.goBackTop();
    } else {
      // 导航栏位置调整后需要修改 index
      this.updateComputeValueIndex(1);
      this.$router.push({ name: "CV-Home" });
    }
  }

  addShoppingCart(): void {
    // 判断是否加入购物车
    if (isInCart(Number(this.modelId))) {
      this.$toast("已经加入购物车！");
    } else {
      this.$get("/big_home/get_product_model_detail", {
        id: this.modelId,
      }).then((res: any) => {
        if (res.data.status === 1) {
          let msg = res.data.data;
          addCart(msg);
          this.$toast("加入成功");
          this.isInCart = true;
        }
      });
    }
  }

  mounted() {
    this.modelId = this.$route.params.id;
    this.renderUrl = `${this.domain}?type=model&modelId=${this.modelId}`;
    this.loaded = true;
    if (isInCart(Number(this.modelId))) {
      this.isInCart = true;
    }
  }
}
